// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


// *************** DEV SITE POINTING TO COGNITO USER POOL **** APPSDEV
const awsmobile = {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_rKz883f2j",
    aws_user_pools_web_client_id: "68bkcclsu7am5vr8o996s6ran0",
    oauth: {
      domain: "appsdev-skfilluminate.auth.us-east-1.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin"
      ],
      redirectSignIn: "https://appsdev5.skfilluminate.net",
      redirectSignOut: "https://appsdev5.skfilluminate.net",
      responseType: "code"
    },
    federationTarget: "COGNITO_USER_POOLS"
  };
  
  
  export default awsmobile;
  
  
  